import React, { useCallback, useMemo, useState } from "react";
import { Grid, Button, Message, Checkbox } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import actions from "actions";
import api from "api";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useAppSelector } from "store";
import SettingActions from "components/lib/SettingActions";

const OrgTagChooser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.user);
  const [tagIds, setTagIds] = useState([]);
  const [saving, setSaving] = useState(false);

  const availableUserTags = useMemo(
    () => user?.ownerOrganisation?.tagList?.filter((tag) => !tag?.disabledEntities?.includes("users")) ?? [],
    [user?.ownerOrganisation?.tagList],
  );

  const toggleTag = useCallback((tag) => {
    setTagIds((prevTags) => {
      const newTags = [...prevTags];
      const tagIndex = newTags.indexOf(tag._id);
      if (tagIndex > -1) {
        newTags.splice(tagIndex, 1);
      } else {
        newTags.push(tag._id);
      }
      return newTags;
    });
  }, []);

  const saveTags = useCallback(() => {
    setSaving(true);
    api.tags.tagDocuments(
      "users",
      tagIds,
      [user._id],
      (ownerTags) => {
        setSaving(false);
        dispatch(actions.user.updateTags(ownerTags));
      },
      () => {
        setSaving(false);
        toast.error("Failed to select tags");
      },
    );
  }, [tagIds, user._id, dispatch]);

  return (
    <>
      <Helmet title={t("tags.title")} />
      <h3>{t("tags.selectTags")}</h3>
      <p>{t("tags.info", { orgName: user?.ownerOrganisation?.name })}</p>
      <Message info compact>
        {t("tags.chooseMany")}
      </Message>

      <Grid stackable columns={2} style={{ marginTop: 20, marginBottom: 20, clear: "both" }}>
        {availableUserTags.map((c) => (
          <Grid.Column key={c._id}>
            <Checkbox toggle checked={tagIds.indexOf(c._id) > -1} onChange={() => toggleTag(c)} label={c.value} />
          </Grid.Column>
        ))}
      </Grid>
      <SettingActions>
        <Checkbox
          toggle
          style={{ marginRight: 30, marginBottom: 10 }}
          label={tagIds.length > 0 ? "Deselect all" : "Select All"}
          checked={tagIds.length > 0}
          onChange={() => {
            if (tagIds.length > 0) {
              setTagIds([]);
            } else {
              setTagIds(availableUserTags.map((t) => t._id));
            }
          }}
        />
        <Button
          onClick={() => {
            saveTags();
          }}
          loading={saving}
          primary
          disabled={!tagIds?.length}
          content={t("generic.continue")}
        />
      </SettingActions>
    </>
  );
};

export default OrgTagChooser;
